import { useLogoApp } from "@/commons/hooks/useLogoApp";
import { reloadColors } from "@/commons/utils/componentsUtils/componentsUtils";
import { ParametersContext } from "@/context/appParametersContext";
import { UserContext } from "@/context/userContext";
import { uiActions } from "@/core/store/slices/uiSlice";
import { userActions } from "@/core/store/slices/userSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { APP_PATH } from "@/router/routes";
import { App } from "@capacitor/app";
import { Device } from "@capacitor/device";
import {
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonRow,
  IonToolbar,
} from "@ionic/react";
import {
  bagOutline,
  batteryChargingOutline,
  bookmarkOutline,
  calendarOutline,
  carOutline,
  cardOutline,
  chatboxOutline,
  helpCircleOutline,
  homeOutline,
  logInOutline,
  logOutOutline,
  moonOutline,
  notificationsOutline,
  personCircleOutline,
  pricetagOutline,
  sunnyOutline,
} from "ionicons/icons";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { IconButton } from "../Buttons/IconButton/IconButton";
import "./Menu.scss";

export const Menu = () => {
  const { logoToolbar } = useLogoApp();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { parameters } = useContext(ParametersContext);
  const { logoutUser } = useContext(UserContext);

  const { theme, themeManual, isUserLogged } = useAppSelector((state) => state.userReducer);

  useEffect(() => {
    handleSetDeviceId();
  }, []);

  useEffect(() => {
    if (theme === "dark") {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }

    reloadColors(parameters.primary_color, theme);
  }, [parameters.primary_color, theme]);

  useEffect(() => {
    setThemeMode();

    const listenerPromises = App.addListener("resume", setThemeMode);

    return () => {
      listenerPromises.then((listener) => listener.remove());
    };
  }, [themeManual]);

  const setThemeMode = () => {
    if (!themeManual) {
      const isDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;
      dispatch(userActions.setTheme(isDarkMode ? "dark" : "light"));
    }
  };

  const handleSetDeviceId = async () => {
    const info = await Device.getId();
    dispatch(userActions.setDeviceId(info.identifier));
  };

  const handleDarkTheme = () => {
    dispatch(userActions.toggleTheme());
  };

  const handleLogout = () => {
    dispatch(uiActions.setAlertType({ type: "logout", options: { primaryAction: logoutUser } }));
  };

  const handleShowHelp = () => {
    dispatch(uiActions.showHelp({ charger: null, step: 0 }));
  };

  return (
    <div id="menu" className="menu">
      <IonMenu side="start" contentId="scheduleAppMenu1" swipeGesture={false}>
        <IonHeader className="ion-no-border">
          <IonToolbar mode="md">
            <IonRow className="menu-header">
              <IonImg src={logoToolbar} />
              <IconButton fill="solid" icon={theme === "dark" ? moonOutline : sunnyOutline} onClick={handleDarkTheme} />
            </IonRow>
          </IonToolbar>
        </IonHeader>
        <IonContent className="secondary">
          <IonList>
            <IonMenuToggle>
              {!isUserLogged ? (
                <IonList>
                  <IonItem
                    routerLink={APP_PATH.HOME}
                    routerDirection="back"
                    lines="none"
                    className="ion-margin-bottom"
                    mode="md"
                  >
                    <IonIcon color="medium" slot="start" icon={homeOutline} />
                    <IonLabel>{t("MENU.HOME")}</IonLabel>
                  </IonItem>

                  {parameters.keychain_active && (
                    <Link to={APP_PATH.SHOP} className="link">
                      <IonItem
                        routerLink={APP_PATH.SHOP}
                        routerDirection="none"
                        lines="none"
                        className="ion-margin-bottom"
                        mode="md"
                      >
                        <IonIcon color="medium" slot="start" icon={bagOutline} />
                        <IonLabel>{t("FILTERS.SHOP")}</IonLabel>
                      </IonItem>
                    </Link>
                  )}

                  <Link to={APP_PATH.CHARGES} replace className="link">
                    <IonItem
                      routerLink={APP_PATH.CHARGES}
                      routerDirection="none"
                      lines="none"
                      className="ion-margin-bottom"
                      mode="md"
                    >
                      <IonIcon color="medium" slot="start" icon={batteryChargingOutline} />
                      <IonLabel>{t("MENU.CHARGES")}</IonLabel>
                    </IonItem>
                  </Link>

                  <IonItem onClick={handleShowHelp} lines="none" className="ion-margin-bottom" mode="md">
                    <IonIcon color="medium" slot="start" icon={helpCircleOutline} />
                    <IonLabel>{t("MENU.HELP")}</IonLabel>
                  </IonItem>

                  <IonItem
                    routerLink={APP_PATH.LOGIN}
                    routerDirection="none"
                    lines="none"
                    className="ion-margin-bottom"
                    mode="md"
                  >
                    <IonIcon color="medium" slot="start" icon={logInOutline} />
                    <IonLabel>{t("ALERT_BUTTONS.LOGIN")}</IonLabel>
                  </IonItem>
                </IonList>
              ) : (
                <>
                  <IonList className="ion-margin-top">
                    <IonItem
                      routerLink={APP_PATH.HOME}
                      routerDirection="none"
                      lines="none"
                      className="ion-margin-bottom"
                      mode="md"
                    >
                      <IonIcon color="medium" slot="start" icon={homeOutline} />
                      <IonLabel>{t("MENU.HOME")}</IonLabel>
                    </IonItem>
                    <Link to={APP_PATH.NOTIFICATIONS} replace className="link">
                      <IonItem routerDirection="none" lines="none" className="ion-margin-bottom" mode="md">
                        <IonIcon color="medium" slot="start" icon={notificationsOutline} className="icon-menu" />
                        <IonLabel>{t("MENU.NOTIFICATIONS")}</IonLabel>
                      </IonItem>
                    </Link>
                    {parameters.keychain_active && (
                      <Link to={APP_PATH.SHOP} className="link">
                        <IonItem
                          routerLink={APP_PATH.SHOP}
                          routerDirection="none"
                          lines="none"
                          className="ion-margin-bottom"
                          mode="md"
                        >
                          <IonIcon color="medium" slot="start" icon={bagOutline} />
                          <IonLabel>{t("FILTERS.SHOP")}</IonLabel>
                        </IonItem>
                      </Link>
                    )}
                  </IonList>

                  <IonList>
                    <IonItem
                      routerLink={APP_PATH.FAVORITES}
                      routerDirection="none"
                      lines="none"
                      className="ion-margin-bottom"
                      mode="md"
                    >
                      <IonIcon color="medium" slot="start" icon={bookmarkOutline} />
                      <IonLabel>{t("MENU.FAVOURITES")}</IonLabel>
                    </IonItem>

                    <Link to={APP_PATH.CHARGES} replace className="link">
                      <IonItem
                        routerLink={APP_PATH.CHARGES}
                        routerDirection="none"
                        lines="none"
                        className="ion-margin-bottom"
                        mode="md"
                      >
                        <IonIcon color="medium" slot="start" icon={batteryChargingOutline} />
                        <IonLabel>{t("MENU.CHARGES")}</IonLabel>
                      </IonItem>
                    </Link>
                    <Link to={APP_PATH.RESERVATIONS} className="link">
                      <IonItem
                        routerLink={APP_PATH.RESERVATIONS}
                        routerDirection="none"
                        lines="none"
                        className="ion-margin-bottom"
                        mode="md"
                      >
                        <IonIcon color="medium" slot="start" icon={calendarOutline} />
                        <IonLabel>{t("MENU.BOOKINGS")}</IonLabel>
                      </IonItem>
                    </Link>
                    <IonItem
                      routerLink={APP_PATH.INCIDENCES}
                      routerDirection="none"
                      lines="none"
                      className="ion-margin-bottom"
                      mode="md"
                    >
                      <IonIcon color="medium" slot="start" icon={chatboxOutline} />
                      <IonLabel>{t("MENU.INCIDENCES")}</IonLabel>
                    </IonItem>

                    <IonItem lines="none" className="ion-margin-bottom" mode="md" onClick={handleShowHelp}>
                      <IonIcon color="medium" slot="start" icon={helpCircleOutline} />
                      <IonLabel>{t("MENU.HELP")}</IonLabel>
                    </IonItem>
                  </IonList>

                  <IonList>
                    <Link to={APP_PATH.PERSONAL_DATA} className="link">
                      <IonItem
                        routerLink={APP_PATH.PERSONAL_DATA}
                        routerDirection="none"
                        lines="none"
                        className="ion-margin-bottom"
                        mode="md"
                      >
                        <IonIcon color="medium" slot="start" icon={personCircleOutline} />
                        <IonLabel>{t("MENU.PERSONAL_DATA")}</IonLabel>
                      </IonItem>
                    </Link>

                    {!parameters.only_b2b && (
                      <Link to={APP_PATH.WALLET} className="link">
                        <IonItem
                          routerLink={APP_PATH.WALLET}
                          routerDirection="none"
                          lines="none"
                          className="ion-margin-bottom"
                          mode="md"
                        >
                          <IonIcon color="medium" slot="start" icon={cardOutline} />
                          <IonLabel>{t("MENU.WALLET")}</IonLabel>
                        </IonItem>
                      </Link>
                    )}

                    <Link to={APP_PATH.VEHICLES} className="link">
                      <IonItem
                        routerLink={APP_PATH.VEHICLES}
                        routerDirection="none"
                        lines="none"
                        className="ion-margin-bottom"
                        mode="md"
                      >
                        <IonIcon color="medium" slot="start" icon={carOutline} />
                        <IonLabel>{t("MENU.VEHICLE")}</IonLabel>
                      </IonItem>
                    </Link>
                    {parameters.keychain_active && (
                      <IonItem
                        routerLink={APP_PATH.KEYCHAIN}
                        routerDirection="forward"
                        lines="none"
                        className="ion-margin-bottom"
                        mode="md"
                      >
                        <IonIcon color="medium" slot="start" icon={pricetagOutline} />
                        <IonLabel>{t("MENU.KEYCHAIN")}</IonLabel>
                      </IonItem>
                    )}
                  </IonList>

                  <IonList>
                    <IonItem lines="none" onClick={handleLogout} mode="md">
                      <IonIcon color="medium" slot="start" icon={logOutOutline} />
                      <IonLabel>{t("MENU.LOG_OUT")}</IonLabel>
                    </IonItem>
                  </IonList>
                </>
              )}
            </IonMenuToggle>
          </IonList>
        </IonContent>
      </IonMenu>
    </div>
  );
};
