import i18n, { dateFnslocalesDate } from "@/assets/i18n/i18n";
import { useFirebaseAnalytics } from "@/commons/hooks/useFirebaseAnalytics";
import { useIsValidUser } from "@/commons/hooks/useIsValidUser";
import { ParametersContext } from "@/context/appParametersContext";
import { UserInfoModel, UserModel, VehicleFormModel } from "@/core/models/userModel";
import { getUserInfoService } from "@/core/services/user/userServices";
import { uiActions } from "@/core/store/slices/uiSlice";
import { userFormActions } from "@/core/store/slices/userFormSlice";
import { userActions } from "@/core/store/slices/userSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { APP_PATH } from "@/router/routes";
import { Capacitor } from "@capacitor/core";
import { Keyboard } from "@capacitor/keyboard";
import { Network } from "@capacitor/network";
import * as Sentry from "@sentry/capacitor";
import { setDefaultOptions } from "date-fns";
import { t } from "i18next";
import { createContext, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useApi } from "../services/apiService";

interface UserContextValue {
  loadUserInfo: () => Promise<UserInfoModel | undefined>;
  logoutUser: () => void;
}

const UserContext = createContext<UserContextValue>({
  loadUserInfo: () => new Promise((resolve) => resolve(undefined)),
  logoutUser: () => {},
});

interface UserProviderProps {
  children: React.ReactNode;
}

const UserProvider = ({ children }: UserProviderProps) => {
  const history = useHistory();
  const { validateUser } = useApi();
  const { parameters } = useContext(ParametersContext);
  const dispatch = useAppDispatch();
  const { checkValidUser } = useIsValidUser();
  const { setUserAnalytics, setLanguageAnalytics } = useFirebaseAnalytics();

  const { user, isUserLogged, guestInfo, deviceId, userInfo } = useAppSelector((state) => state.userReducer);
  const { requiredFields } = useAppSelector((state) => state.userFormReduce);

  useEffect(() => {
    const platform = Capacitor.getPlatform();
    if (platform !== "web") {
      const showListener = Keyboard.addListener("keyboardWillShow", () => {
        dispatch(uiActions.setIsKeyboardOpen(true));
      });

      const hideListener = Keyboard.addListener("keyboardWillHide", () => {
        dispatch(uiActions.setIsKeyboardOpen(false));
      });

      return () => {
        showListener.then((listener) => listener.remove());
        hideListener.then((listener) => listener.remove());
      };
    }
  }, []);

  useEffect(() => {
    const listenerPromise = Network.addListener("networkStatusChange", (status) => {
      dispatch(uiActions.setNetwork(status));
    });

    return () => {
      listenerPromise.then((listener) => listener.remove());
    };
  }, []);

  useEffect(() => {
    setUserAnalytics(user.user_id);
  }, [user.user_id]);

  useEffect(() => {
    setDefaultOptions({ locale: dateFnslocalesDate[i18n.language] || dateFnslocalesDate["en"] });
    setLanguageAnalytics(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    if (parameters.token_api) {
      checkIfUserIsLogged(user);
    }
  }, [user, parameters.token_api]);

  useEffect(() => {
    if (isUserLogged && userInfo) {
      const pathname = window.location.pathname;

      if (!userInfo.vehicle_id) {
        if (pathname !== APP_PATH.VEHICLES) {
          history.replace(`${APP_PATH.VEHICLES}?createVehicle=true`);
        }

        return;
      }

      if (!checkValidUser(userInfo, true)) {
        if (pathname !== APP_PATH.PERSONAL_DATA) {
          history.replace(`${APP_PATH.PERSONAL_DATA}?showError=true`);
        }
        return;
      }

      dispatch(userActions.setHaveRequiredFields(true));
    }
  }, [isUserLogged, userInfo, requiredFields]);

  const checkIfUserIsLogged = async (newUser: UserModel) => {
    const userGuestId = parameters.user_guest_id || -1;

    if (userGuestId === -1) {
      dispatch(uiActions.setToastError(t("APP_ERRORS.GUEST_USER")));
      return;
    }

    // First load set user guest id
    if (!newUser.user_id || newUser.user_id === -1) {
      return dispatch(
        userActions.setUser({
          user: {
            user_id: userGuestId,
            auth_token: parameters.token_api_guest,
          },
        }),
      );
    }

    let logged = false;
    localStorage.setItem("token", newUser.auth_token);

    if (newUser.user_id !== userGuestId) {
      const validUser = await validateUser(newUser.auth_token, newUser.user_id);
      if (!validUser?.valid) {
        return logoutUser();
      }

      logged = true;
    }

    loadUserInfo(newUser);

    return logged;
  };

  const loadUserInfo = async (newUser?: UserModel) => {
    if (parameters.token_api) {
      try {
        newUser = newUser || user;

        const userId = !isUserLogged ? parameters.user_guest_id : newUser.user_id;

        const newUserInfo = await getUserInfoService(userId, deviceId);
        const vehicles = newUserInfo.user.vehicles || [];

        dispatch(userActions.setUserInfo(newUserInfo.user));
        dispatch(userFormActions.initRequiredFields(newUserInfo.user));
        dispatch(userActions.setHaveRequiredFields(false));

        if (!isUserLogged) {
          const vehicle = vehicles[0];

          let newVehicle: VehicleFormModel | null = guestInfo.vehicle;

          if (vehicle) {
            newVehicle = {
              carId: vehicle.car_id,
              vehicleBrandId: Number(vehicle.vehicle_brand_id),
              vehicleModelId: Number(vehicle.vehicle_model_id),
              vehicle: vehicle.vehicle,
              model: vehicle.model,
            };
          }

          dispatch(
            userActions.setGuestInfo({
              email: guestInfo.email,
              vehicle: newVehicle,
            }),
          );
        }

        Sentry.setExtra("user-app", `${userId}-${newUserInfo.user.email}`);
        Sentry.setTag("user-app-tag", `${userId}-${newUserInfo.user.email}`);

        return newUserInfo.user;
      } catch (error) {
        // TODO: logout user if can't get user info and redirect to login
        dispatch(uiActions.setToastError(error));
      }
    }
  };

  const logoutUser = () => {
    const { user_guest_id, token_api_guest } = parameters;
    dispatch(
      userActions.setUser({
        user: {
          user_id: user_guest_id,
          auth_token: token_api_guest,
        },
      }),
    );
    dispatch(userActions.setHaveRequiredFields(false));

    localStorage.setItem("token", token_api_guest);

    dispatch(uiActions.setToast({ show: true, message: t("SUCCESS.SESSION_CLOSE"), color: "success" }));

    history.replace(APP_PATH.HOME);

    // TODO: change this timeout to redux thunk action
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };
  return (
    <UserContext.Provider value={{ loadUserInfo, logoutUser }}>
      {parameters && user && <>{children}</>}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
