import { ModalHeader } from "@/commons/components/Headers/ModalHeader";
import { PaymentStep } from "@/commons/components/Payments/PaymentStep/PaymentStep";
import { useParametersContext } from "@/commons/hooks/useParametersContext";
import { useQuery } from "@/commons/hooks/useQuery";
import { useUserContext } from "@/commons/hooks/useUserContext";
import { getSocketInfoPrice } from "@/commons/utils/socketUtils/socketUtils";
import { PaymentMethod } from "@/core/models/PaymentModel";
import { ChargeStepLabel, chargeStepsActions } from "@/core/store/slices/chargeStepsSlice";
import { mapActions } from "@/core/store/slices/mapSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { APP_PATH } from "@/router/routes";
import {
  IonContent,
  IonIcon,
  IonLabel,
  IonModal,
  IonSegment,
  IonSegmentButton,
  SegmentCustomEvent,
} from "@ionic/react";
import { t } from "i18next";
import { checkmarkCircle } from "ionicons/icons";
import { ReactElement, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ChargeStepActivate } from "./ChargeStepActivate/ChargeStepActivate";
import { ChargeStepCharge } from "./ChargeStepCharge/ChargeStepCharge";
import { ChargeStepChoose } from "./ChargeStepChoose/ChargeStepChoose";
import "./ChargeSteps.scss";
import { ChargeStepsFooter } from "./ChargeStepsFooter/ChargeStepsFooter";

interface ChargeStep {
  label: ChargeStepLabel;
  component: ReactElement;
}

export const ChargeSteps = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { query } = useQuery();

  const { loadUserInfo } = useUserContext();
  const {
    parameters: { payment_type: paymentType },
  } = useParametersContext();

  const { options, selectedSocket, selectedCharger } = useAppSelector((state) => state.mapReducer.selectedLocation);
  const { currentStep, chargeStep, chooseStep, openInLastStep, activeStep } = useAppSelector(
    (state) => state.chargeStepsReducer,
  );
  const { userInfo, isUserLogged, guestInfo } = useAppSelector((state) => state.userReducer);

  const [steps, setsteps] = useState<ChargeStep[]>([]);

  // Load data
  useEffect(() => {
    options.showChargeSteps && loadUserInfo();
  }, [options.showChargeSteps]);

  useEffect(() => {
    if (selectedCharger && selectedSocket && options.showChargeSteps) {
      const newSteps: ChargeStep[] = [{ label: ChargeStepLabel.CHOOSE, component: <ChargeStepChoose /> }];

      if (
        (isUserLogged && userInfo?.vehicles?.length !== 1) ||
        (!isUserLogged && userInfo?.vehicles?.length === 0 && !guestInfo.vehicle)
      ) {
        if (!activeStep.vehicleId && userInfo) {
          // Set default vehicle active
          const defaultVehicle = userInfo.vehicles.find((vehicle) => vehicle.predetermined);
          defaultVehicle && dispatch(chargeStepsActions.setActiveVehicleId(defaultVehicle.vehicle_id));
        }

        newSteps.push({ label: ChargeStepLabel.ACTIVATE, component: <ChargeStepActivate /> });
      } else if (isUserLogged && userInfo && userInfo.vehicles.length === 1) {
        dispatch(chargeStepsActions.setActiveVehicleId(userInfo.vehicles[0].vehicle_id));
      } else {
        if (guestInfo.email) {
          dispatch(chargeStepsActions.setActiveStepEmail(guestInfo.email));
        }

        if (guestInfo.vehicle?.vehicle_id) {
          dispatch(chargeStepsActions.setActiveVehicleId(guestInfo.vehicle?.vehicle_id));
        }
      }

      let { isFreeCharge } = getSocketInfoPrice(selectedSocket);
      isFreeCharge = isFreeCharge || (chooseStep.energy > 0 && chooseStep.price === 0);

      if (!isFreeCharge && selectedCharger.payment_methods && !selectedSocket.is_b2b_user) {
        setDefaultPaymentMethod(selectedCharger.payment_methods);

        newSteps.push({ label: ChargeStepLabel.PAYMENT, component: <PaymentStep stepsType="charge" /> });
      }

      newSteps.push({ label: ChargeStepLabel.CHARGE, component: <ChargeStepCharge /> });

      setsteps(newSteps);
      dispatch(chargeStepsActions.setStepsLabels(newSteps.map((step) => step.label)));

      const newCurrentStep = openInLastStep ? newSteps.length - 1 : currentStep;
      dispatch(chargeStepsActions.setCurrentStep(newCurrentStep));
    }
  }, [userInfo, isUserLogged, guestInfo, selectedSocket, options.showChargeSteps, currentStep]);

  const handleDismissModal = () => {
    const openLocationDetail = location.pathname === APP_PATH.HOME && !!query.locationId;

    dispatch(chargeStepsActions.setCleanChargeSteps());

    dispatch(
      mapActions.setLocationOptions({ ...options, showChargeSteps: false, showLocationDetail: openLocationDetail }),
    );
  };

  const handleStepClick = (event: SegmentCustomEvent) => {
    const newValue = event.detail.value;
    dispatch(chargeStepsActions.setCurrentStep(Number(newValue)));
  };

  const setDefaultPaymentMethod = (payment_methods: PaymentMethod[]) => {
    const isBothPayment = paymentType.includes("both");

    if (!isUserLogged) {
      dispatch(chargeStepsActions.setPaymentStepPaymentMethod("CREDITCARD"));
      return;
    }

    if (isBothPayment && payment_methods.length > 1) {
      dispatch(chargeStepsActions.setPaymentStepCanChangePaymentMethod(true));
      const defaultPaymentMethod =
        userInfo?.default_charge_values.active_automatic_charge &&
        userInfo?.default_charge_values.default_payment_method;
      if (defaultPaymentMethod) {
        return dispatch(
          chargeStepsActions.setPaymentStepPaymentMethod(
            defaultPaymentMethod === "credit_card" ? "CREDITCARD" : "WALLET",
          ),
        );
      }

      if (payment_methods.includes("WALLET")) {
        return dispatch(chargeStepsActions.setPaymentStepPaymentMethod("WALLET"));
      }
    }
    if (isBothPayment && payment_methods.length === 1) {
      dispatch(chargeStepsActions.setPaymentStepPaymentMethod(payment_methods[0]));
      return;
    }

    if (payment_methods.length > 1) {
      dispatch(chargeStepsActions.setPaymentStepCanChangePaymentMethod(true));
    }

    if (paymentType.includes("wallet") && payment_methods.some((method) => method === "WALLET")) {
      dispatch(chargeStepsActions.setPaymentStepPaymentMethod("WALLET"));
    } else {
      dispatch(chargeStepsActions.setPaymentStepPaymentMethod("CREDITCARD"));
    }
  };

  return (
    <IonModal
      className="charge-steps"
      isOpen={options.showChargeSteps}
      canDismiss={!chargeStep.isChargeInit}
      onDidDismiss={handleDismissModal}
    >
      {options.showChargeSteps && (
        <>
          <ModalHeader
            title="TITLES.CHOOSE_CHARGE"
            handleDismissModal={handleDismissModal}
            hiddenCloseButton={chargeStep.isChargeInit}
          />
          <IonContent>
            <IonSegment className="segment" value={currentStep} onIonChange={handleStepClick} mode="md">
              {steps.map((step, index) => (
                <IonSegmentButton
                  key={step.label}
                  value={index}
                  disabled={index > currentStep || (currentStep + 1 === steps.length && index + 1 !== steps.length)}
                >
                  {index < currentStep ? (
                    <IonIcon className="ion-no-margin" icon={checkmarkCircle} color="primary" />
                  ) : (
                    <IonLabel class="ion-no-margin">{index + 1}</IonLabel>
                  )}
                  <IonLabel className="ion-no-margin">{t(`INSTRUCTIONS_STEPS.${step.label}`)}</IonLabel>
                </IonSegmentButton>
              ))}
            </IonSegment>
            <div className="charge-steps-component">
              {steps.length > 0 && steps[currentStep] && steps[currentStep].component}
            </div>
          </IonContent>
          <ChargeStepsFooter />
        </>
      )}
    </IonModal>
  );
};
