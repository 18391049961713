import { formatNumberDecimals } from "@/commons/utils/numberUtils";
import { IonIcon, IonLabel } from "@ionic/react";
import { format } from "date-fns";
import { batteryHalfOutline } from "ionicons/icons";
import { TooltipProps } from "recharts";
import { ChargeChartData } from "./ChargeChart";
import "./ChargeChartTooltip.scss";

export const ChargeChartTooltip = ({ active, payload }: TooltipProps<number, string>) => {
  if (active && payload && payload.length) {
    const { date, power, energy, percentBattery }: ChargeChartData = payload[0].payload;

    return (
      <div className="charge-chart-tooltip">
        <div className="charge-chart-tooltip-date">{`${format(new Date(date), "dd-MM-yyyy HH:mm")}`}</div>
        <div className="charge-chart-tooltip-info power">
          <div className="dot" />
          {`${formatNumberDecimals(power)} kWh`}
        </div>
        <div className="charge-chart-tooltip-info energy">
          <div className="dot" />
          {`${formatNumberDecimals(energy)} kWh`}
        </div>
        {percentBattery && (
          <IonLabel className="active-charge-monitoring-label-time">
            <IonIcon className="active-charge-icon" icon={batteryHalfOutline} />
            <span>{percentBattery}%</span>
          </IonLabel>
        )}
      </div>
    );
  }

  return null;
};
