import { format } from "date-fns";
import { t } from "i18next";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { ChargeChartTooltip } from "./ChargeChartTooltip";

export interface ChargeChartData {
  date: Date;
  time: string;
  power: number;
  energy: number;
  percentBattery?: number;
}

interface ChargeChartProps {
  data: ChargeChartData[];
}

// This component need a parent with width and height

export const ChargeChart = ({ data }: ChargeChartProps) => {
  const dataToShow: ChargeChartData[] = data.length
    ? data
    : [{ power: 0, energy: 0, time: format(new Date(), "HH:mm"), date: new Date() }];

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        data={dataToShow}
        margin={{
          top: 5,
          right: 20,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid vertical={false} />
        <Legend verticalAlign="top" iconType="circle" height={36} />

        <XAxis type="category" dataKey="time" dy={8} allowDuplicatedCategory={false} />
        <YAxis
          axisLine={false}
          label={{
            value: "kWh",
            angle: -90,
            position: "insideLeft",
          }}
        />
        <Tooltip content={<ChargeChartTooltip />} />
        <Line
          name={t("INFO_CHARGE.POWER")}
          type="natural"
          strokeWidth={3}
          dataKey="power"
          stroke="var(--ion-color-secondary-contrast)"
          dot={{ r: 2 }}
          activeDot={{ r: 8 }}
        />
        <Line
          name={t("INFO_CHARGE.ENERGY")}
          type="linear"
          strokeWidth={3}
          dataKey="energy"
          stroke="var(--ion-color-primary)"
          dot={{ r: 1 }}
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
