import { useFirebaseAnalytics } from "@/commons/hooks/useFirebaseAnalytics";
import { useParametersContext } from "@/commons/hooks/useParametersContext";
import { getURLDomain } from "@/commons/utils/apiUtils/apiUtils";
import { ErrorModel } from "@/core/models/apiModel";
import { createTokenUserService, loginUserService } from "@/core/services/user/userServices";
import { uiActions } from "@/core/store/slices/uiSlice";
import { userActions } from "@/core/store/slices/userSlice";
import { useAppDispatch } from "@/core/store/store";
import { IonButton, IonIcon, IonInput, IonItem, IonLabel, IonList, IonLoading } from "@ionic/react";
import { eyeOffOutline, eyeOutline } from "ionicons/icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const LoginEmail = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { setLogEvent } = useFirebaseAnalytics();
  const { parameters } = useParametersContext();

  const [isLoading, setIsLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async () => {
    try {
      setIsLoading(true);
      const response = await loginUserService({ email, password });

      const userId = response.user_id;
      let authToken = response.auth_token;
      const authenticated = response.authenticated === "true";

      if (!authenticated) {
        dispatch(uiActions.setAlertType({ type: "badCredentials" }));
        console.error("Credentials are wrong");
        return;
      }

      if (!authToken) {
        const { new_token } = await createTokenUserService(userId, parameters.token_api);
        authToken = new_token;
      }

      // if userToken is still after trying to create a new one
      if (!authToken) {
        dispatch(uiActions.setToastError(t("APP_ERRORS.ADD_PAYMENT")));
        return;
      }

      dispatch(
        userActions.setUser({
          user: {
            user_id: userId,
            auth_token: authToken,
          },
          userType: "logged",
        }),
      );

      setLogEvent("login", { method: "email" });
    } catch (error) {
      const errorModel = error as ErrorModel;
      console.error("Error login email", error, errorModel.jsonError);

      const errorCode = errorModel.jsonError?.error_code;
      if (errorCode === "-18" || errorCode === "-24") {
        return dispatch(uiActions.setAlertType({ type: "badCredentials" }));
      }

      dispatch(uiActions.setAlertType({ type: "error" }));
    } finally {
      setIsLoading(false);
    }
  };

  const handleEmailChange = (e: CustomEvent) => {
    setEmail(e.detail.value);
  };

  const handlePasswordChange = (e: CustomEvent) => {
    setPassword(e.detail.value);
  };

  return (
    <>
      <IonLoading isOpen={isLoading} />
      <IonList lines="none" className="loginPageContent">
        <IonItem lines="full">
          <IonInput
            label={t("PROFILE.EMAIL")}
            labelPlacement="floating"
            onIonInput={handleEmailChange}
            type="text"
            value={email}
          />
        </IonItem>
        <IonItem className="ion-margin-top ion-margin-bottom" lines="full">
          <IonInput
            label={t("PROFILE.PASSWORD")}
            labelPlacement="floating"
            onIonInput={handlePasswordChange}
            type={showPassword ? "text" : "password"}
            value={password}
          />
          <IonIcon icon={showPassword ? eyeOffOutline : eyeOutline} onClick={toggleShowPassword} slot="end" />
        </IonItem>
        <IonItem>
          <IonLabel className="forgotPassword">
            <a href={getURLDomain("users/password/new", undefined, { isRootPath: true })}>
              {t("BUTTONS.FORGOT_PASSWORD")}
            </a>
          </IonLabel>
        </IonItem>
      </IonList>

      <IonItem className="ion-no-border">
        <IonButton
          onClick={handleLogin}
          style={{ padding: "0 16px", margin: "16px 0", fontSize: "16px", fontWeight: "bold" }}
        >
          {t("ALERT_BUTTONS.LOGIN")}
        </IonButton>
      </IonItem>
    </>
  );
};
