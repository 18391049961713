import i18n from "@/assets/i18n/i18n";
import { LOGINS_TYPES } from "@/commons/constants/login";
import { useFirebaseAnalytics } from "@/commons/hooks/useFirebaseAnalytics";
import { useParametersContext } from "@/commons/hooks/useParametersContext";
import { createTokenUserService } from "@/core/services/user/userServices";
import { uiActions } from "@/core/store/slices/uiSlice";
import { userActions } from "@/core/store/slices/userSlice";
import { useAppDispatch } from "@/core/store/store";
import { useApi } from "@/services/apiService";
import { IonAlert, IonLoading, IonicSafeString } from "@ionic/react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { LoginApple } from "./LoginApple/LoginApple";
import { LoginGoogle } from "./LoginGoogle/LoginGoogle";

interface LoginSocialProps {
  showIosButton: boolean;
}

export interface LoginUserInfo {
  email: string;
  name: string;
  surname1: string;
  surname2: string;
  accessToken: string;
  password: string;
  id_apple_user?: string;
}

export const LoginSocial = ({ showIosButton }: LoginSocialProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { getLoginGmail, postRegisterUser, getLoginApple } = useApi();
  const { setLogEvent } = useFirebaseAnalytics();

  const { parameters } = useParametersContext();

  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const allowed_logins = useMemo(() => parameters.allowed_logins?.split(",") || [], [parameters.allowed_logins]);

  const checkRegistered = (userInfo: LoginUserInfo, type: LOGINS_TYPES) => {
    setIsLoading(true);
    loginAPI(userInfo, type);
  };

  const setUserToken = async (userId: number, userToken: string) => {
    if (!userToken) {
      try {
        const { new_token } = await createTokenUserService(userId, parameters.token_api);
        userToken = new_token;
      } catch (error) {
        dispatch(uiActions.setToastError(error));
      }
    }

    // if userToken is still after trying to create a new one
    if (!userToken) {
      dispatch(uiActions.setToastError(t("APP_ERRORS.ADD_PAYMENT")));
      return;
    }

    dispatch(
      userActions.setUser({
        user: {
          user_id: userId,
          auth_token: userToken,
        },
        userType: "logged",
      }),
    );
  };

  const loginAPI = async (userInfo: LoginUserInfo, type: LOGINS_TYPES) => {
    let data: any;
    if (type === LOGINS_TYPES.GOOGLE) {
      data = await getLoginGmail(userInfo.email, userInfo.accessToken);
    } else if (type === LOGINS_TYPES.APPLE) {
      data = await getLoginApple(userInfo.email, userInfo.accessToken, userInfo.id_apple_user);
    }
    if (data?.response) {
      setIsLoading(false);
      const userId = data.user_id;
      const authToken = data.auth_token;
      setUserToken(userId, authToken);
    } else {
      await registerUser(userInfo, type);
    }

    setLogEvent("login", { method: type });
  };

  const registerUser = async (userInfo: LoginUserInfo, type: LOGINS_TYPES) => {
    setIsLoading(true);

    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append("email", userInfo.email);
    urlSearchParams.append("password", userInfo.password);
    urlSearchParams.append("name", userInfo.name);
    urlSearchParams.append("surname1", userInfo.surname1);
    urlSearchParams.append("surname2", userInfo.surname2);
    urlSearchParams.append("idioma", i18n.language);
    urlSearchParams.append("domain_id", `${parameters.domain_id}`);
    urlSearchParams.append("dni_nif", "");
    urlSearchParams.append("active", "true");
    urlSearchParams.append("telf", "");
    urlSearchParams.append("ubicacio", "");
    urlSearchParams.append("poblacio", "");
    urlSearchParams.append("cp", "");
    urlSearchParams.append("app_connector_default", "");
    urlSearchParams.append("car_id", "");
    urlSearchParams.append("vehicle", "");
    urlSearchParams.append("model", "");
    urlSearchParams.append("notify_commercial", "false");
    urlSearchParams.append("privacy_policy", "false");
    urlSearchParams.append("confirm", "true");
    userInfo.id_apple_user && urlSearchParams.append("id_apple_user", userInfo.id_apple_user);

    urlSearchParams.append("billing_user", "false");

    const register: any = await postRegisterUser(urlSearchParams);

    if (!register || register.length === 0 || register["error_code"]) {
      setIsLoading(false);
      setShowAlert(true);
      return;
    }

    loginAPI(userInfo, type);
  };

  const handleAlertDismiss = () => {
    setShowAlert(false);
  };

  return (
    <>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={handleAlertDismiss}
        message={
          new IonicSafeString(
            `<div class="alert">
              <ion-label class="alertText">${t("ALERT.SOMETHING_WRONG")}</ion-label>
            </div>`,
          )
        }
        buttons={[
          {
            text: `${t("BUTTONS.ACCEPT")}`,
            handler: handleAlertDismiss,
          },
        ]}
        cssClass="alertContainer"
      />
      <IonLoading isOpen={isLoading} message={t("LOADING.LOADING")} />
      {showIosButton && allowed_logins.includes(LOGINS_TYPES.APPLE) && (
        <LoginApple checkRegistered={checkRegistered} setShowAlert={setShowAlert} />
      )}
      {allowed_logins.includes(LOGINS_TYPES.GOOGLE) && (
        <LoginGoogle checkRegistered={checkRegistered} setShowAlert={setShowAlert} />
      )}
    </>
  );
};
